.side-panel-container{
    background-color:white;
    width:24%;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:20;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.034);    

}
.side-panel-container.active{
  transform: translateX(0%);
}
.visualization-container{
    overflow-y:auto;
    width:100%;
    height:83vh;
}
.toggle-side-panel-btn{
    position:absolute;
    top:3.5rem;
    right:-1.63rem;
    z-index:20;
    cursor: pointer;
    background-color: #ffffff;
    border:1px solid #ffffff;
    display:flex;
    justify-content: center;
    align-items:center;
    width:25px;
    height:25px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.25);

}
.side-panel-card-header{
    background-color: #83bb45;
    color:#ffffff;
    cursor: pointer;
}
.grid-view-icon{
    font-size:20px;
    cursor: pointer;
}
.grid-view-modal-body{
    overflow-y:auto;
    height:80vh;
    width:100%;
}
.visualisierung-card{
    width:100%;
    min-height:200px;
    cursor: pointer;
    button{
        margin-right:1px;
        background-color: #83bb45;
        color:#ffffff;
        &:hover{
            color:#ffffff; 
        }
    }
}
#dropdown-item-button{
    // width:100%;
    // color:black;
    // background-color: white;
    // border:1px solid black;
    &:focus{
        box-shadow:none;
       -webkit-box-shadow:none;
    }
}
.card-body{
    min-height:200px;
}
.chart-image{
    height:180px;

}
@media (max-width:599px){
    .side-panel-container{
        width:90%;
    }
     
     
    
 }
 
 @media (min-width:600px){
    .side-panel-container{
        width:40%;
    }
 
 }
 
 @media (min-width:900px){
    
   
 }
 @media (min-width:1200px){
    .side-panel-container{
        width:24%;
    }
 }
 @media (min-width:1500px){
    
     
 }