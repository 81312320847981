.login-section{
    min-height:100vh;
    background-image:url("../../assets/images/golf.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h2{
        color:#436d16;
        font-weight: 400;
        font-size:4.2rem;
        letter-spacing: 1px;
        margin-bottom:4rem;
    }

    .login-card{
        width:400px;
        min-height:300px;
        border: none;
        background:#ffffff;
        
        .card-header{
            background-color: #83bb45;
            color:#ffffff;
            border:none;
        }
        .card-body{
            padding:1rem;
        }

        .login-button{
            background-color: #83bb45;
            color:#ffffff;

        }
        .register-button{
            color:#436d16;
            text-decoration: underline;
            text-decoration-color:#436d16;
            
        }
        
    }
}
@media (max-width:599px){
   .login-section{
       h2{
           font-size:3rem;
       }
       .login-card{
        width:100%;
    }
   }
    
    
   
}

@media (min-width:600px){
   

}

@media (min-width:900px){
   
  
}
@media (min-width:1200px){
  
}
@media (min-width:1500px){
   
    
}