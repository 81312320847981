.nav-link{
    color:#ffffff !important;
}
.admin-img-container{
    .admin-img{
        width:70px;
        height:40px;
        clip-path: circle();
        cursor: pointer;
    }
}