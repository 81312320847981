.user-view-container{
    width:100%;
    min-height:100vh;
    background-color:rgba(0, 128, 0, 0.404);
}
.map-container{
    position: relative;

    .loch{
        position:absolute;
        top:0;
        display:flex;
        justify-content: center;
        z-index: 99;
    }
}
.loading-screen{
    position:absolute;
    top:0;
    z-index:99;
    width:100%;
    min-height:100vh;
    background-color:rgba(0, 0, 0, 0.719);
    display:flex;
    justify-content: center;
    align-items: center;
}
.loading-card{
    width:400px;
    height:300px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{
        font-size:1rem;
        font-weight:bold;
    }
}